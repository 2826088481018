import {AppConfiguration} from "../../app/interfaces/app-configuration";

export const eveAppConfiguration: AppConfiguration = {
  navMobileLogSrc: "assets/TAI-logo-small.svg",
  saveTermsAndConditionSettings: true,
  useCustomContentBlock: true,
  backgroundImageClass: "custom-background-image",
  navButtonClass: "contact-button-nav-triple-ai",
  navLogSrc: "assets/tripleai-vector.svg",
  contactDetailCtaButtonLabel: "Get Started",
  navContactButtonLabel: "Book a Test drive",
  avatarBorderRadius: 48,
  avatarControlsHeight: 48,
  avatarControlsWidth: 48,
  inputFieldClassName: 'eve-input',
  chatOverlayClassName: 'chat-overlay',
  subtitleClassName: 'eve-font',
  useCustomDialogs: true,
  privacyPolicyUrl: 'https://www.tripleai.co/privacy-policy',
  welcomeImageSrc: 'assets/bg2.jpg',
  termsAndConditionsUrl: 'https://www.tripleai.co/terms-and-conditions-eve-singapore',
  disableImageSelectionSync: true,
  avatarName: 'EVE',
  useCustomLandingPage: true
}
