<div
  *ngIf="!item.isImageOnlyContent"
  [style.width.px]="baseDimensionWidth"
  [style.height.px]="baseDimensionHeight"
  style="border-radius: 26px;
background: rgba(236,237,239,0.88);margin: 0 12px;"
>
  <div class="content-image d-flex justify-content-between"
       style="min-height: 200px; min-width: 100%; border-radius: 25px 25px 0 0;background-size: cover;"
       [style.background-image]="'url('+ mainImageLink+')'"
       [style.min-height.px]="mainImageHeight"
       [style.min-width.px]="mainImageWidth"
  >
    <div class="d-flex justify-content-center align-items-center" [style.height.px]="mainImageHeight" [style.width.px]="40" (click)="decreaseMainImageIndex()">
      <!--left -->
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
      </svg>
    </div>
    <div class="d-flex justify-content-center align-items-center" [style.height.px]="mainImageHeight" [style.width.px]="40" (click)="increaseMainImageIndex()">
     <!-- right -->
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
      </svg>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center" style="height: 32px; width: 100%;gap: 8px;">
    <div style="width: 8px;
height: 8px;background: rgb(189,190,196); border-radius: 8px;" *ngFor="let dot of item.imageUrls"
         [class.bg-black]="dot == mainImageLink" (click)="mainImageLink = dot"></div>
  </div>
  <div style="padding-right: 20px; padding-left: 20px; padding-bottom: 16px;">
    <div style="color: #010205;
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 120%;">
      {{ item.contentId }}
    </div>
    <div style="color: rgba(1, 2, 5, 0.60);
font-family: Manrope;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 120%;">
      {{ item.price }}
    </div>
    <div class="d-flex" style="margin-top: 12px;">
      <div *ngFor="let spec of item.table">
        <div style="color: rgba(1, 2, 5, 0.60);
font-family: Manrope;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 120%;">
          {{ spec.key }}
        </div>
        <div style="color: #010205;
font-family: Manrope;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: 110%;
letter-spacing: -0.176px;"
        >
          {{ spec.value }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="item.isImageOnlyContent"
     style="border-radius: 26px;
background: rgba(236,237,239,0.88);margin: 0 12px;">
  <div class="content-image"
       style="min-height: 200px; min-width: 100%; border-radius: 25px; background-size: cover; height: 100%; width: 100%;"
       [style.background-image]="'url('+ mainImageLink+')'"
       [style.min-height.px]="mainImageHeight"
       [style.min-width.px]="mainImageWidth"
  >
  </div>
</div>
